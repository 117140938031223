import React, { useEffect } from "react"
import { graphql, Link } from "gatsby"
import Video from "../components/Video/video"
import "../styles/projects.css"

function Stories({ data }) {
  const client = data.allAirtable.nodes[0]

  useEffect(() => {
    const page = document.getElementsByTagName("HTML")[0]
    page.style.backgroundImage = `url(${client.data.bgImage})`
    page.classList.add("html")
    document.body.classList.add("body")
  }, [client.data.bgImage])

  return (
    <div className="client">
      <h1>
        <Link to="/">Brightly</Link>
      </h1>
      <div className="client__container">
        <Video videoSrcURL={client.data.poem} title="Poem" />
        <Video videoSrcURL={client.data.novella} title="Novella" />
        <Video videoSrcURL={client.data.novel} title="Novel" />
        <Video videoSrcURL={client.data.prose} title="Prose" />
        <Video videoSrcURL={client.data.prologue} title="Prologue" />
        <a href={client.data.videos}>Download Videos</a>
        <a href={client.data.soundtrack}>Download Soundtrack</a>
      </div>
    </div>
  )
}

export default Stories

export const query = graphql`
  query ProjectsQuery {
    allAirtable {
      nodes {
        data {
          poem
          bgImage
          novel
          novella
          prologue
          prose
          soundtrack
          videos
        }
      }
    }
  }
`
