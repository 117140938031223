import React from "react"
import "./video.css"

const Video = ({ title, videoSrcURL, ...props }) => (
  <div className="video">
    <h2 className="video__title">{title}</h2>
    <iframe
      title={title}
      src={videoSrcURL}
      frameBorder="0"
      allowFullScreen
    ></iframe>
  </div>
)

export default Video
